<template>
  <div class="container bg-white">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('privacy.pengungkapanInformasiPengguna.title') }}</h3>
        <div class="divider"></div>
        <p>{{ $t('privacy.pengungkapanInformasiPengguna.line1') }}</p>
        <ul>
          <li>{{ $t('privacy.pengungkapanInformasiPengguna.line2') }}</li>
          <li>{{ $t('privacy.pengungkapanInformasiPengguna.line3') }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pengungkapan-informasi-pengguna',
};
</script>
