<template>
  <div class="container bg-white" id="penutupan-akun">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('privacy.penutupanAkun.title') }}</h3>
        <div class="divider"></div>
        <p>{{ $t('privacy.penutupanAkun.line1') }}</p>
        <ul>
          <li>{{ $t('privacy.penutupanAkun.line2') }}</li>
          <li>{{ $t('privacy.penutupanAkun.line3') }}</li>
          <li>{{ $t('privacy.penutupanAkun.line4') }}</li>
        </ul>
        <p>{{ $t('privacy.penutupanAkun.line5') }}</p>
        <ul>
          <li>{{ $t('privacy.penutupanAkun.line6') }}</li>
          <li>{{ $t('privacy.penutupanAkun.line7') }}</li>
          <li>{{ $t('privacy.penutupanAkun.line8') }}</li>
          <li>{{ $t('privacy.penutupanAkun.line9') }}</li>
          <li>{{ $t('privacy.penutupanAkun.line10') }}</li>
          <li>{{ $t('privacy.penutupanAkun.line11') }}</li>
          <li>{{ $t('privacy.penutupanAkun.line12') }}</li>
          <li>{{ $t('privacy.penutupanAkun.line13') }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'penutupan-akun',
};
</script>
